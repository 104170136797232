import React from 'react'
import { type LucideProps } from 'lucide-react'

const Search: React.FC<LucideProps> = (props: LucideProps) => (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M8.62913 17.2353C6.44292 17.2353 4.36702 16.3768 2.81654 14.8275C1.26607 13.277 0.408691 11.2292 0.408691 9.01487C0.408691 6.82867 1.26718 4.75276 2.81654 3.20228C4.367 1.65181 6.41481 0.794434 8.62913 0.794434C10.8153 0.794434 12.8912 1.65292 14.4417 3.20228C15.9922 4.75274 16.8496 6.80055 16.8496 9.01487C16.8496 11.2011 15.9911 13.277 14.4417 14.8275C12.8923 16.405 10.8162 17.2353 8.62913 17.2353ZM8.62913 2.17746C6.80188 2.17746 5.08598 2.89755 3.7853 4.19824C2.48469 5.49894 1.76453 7.21479 1.76453 9.04206C1.76453 10.8693 2.48462 12.5852 3.7853 13.8859C5.08601 15.1865 6.80185 15.9067 8.62913 15.9067C10.4564 15.9067 12.1723 15.1866 13.473 13.8859C14.7736 12.5852 15.4937 10.8693 15.4937 9.04206C15.4937 7.21481 14.7736 5.49892 13.473 4.19824C12.1723 2.89762 10.4564 2.17746 8.62913 2.17746Z" fill="white" />
        <path d="M17.7084 18.7845C17.5419 18.7845 17.3764 18.7294 17.238 18.591L13.4732 14.8543C13.1964 14.5775 13.1964 14.1623 13.4732 13.9136C13.75 13.6369 14.1652 13.6369 14.4139 13.9136L18.1787 17.6785C18.4555 17.9552 18.4555 18.3704 18.1787 18.6191C18.0403 18.7294 17.8749 18.7845 17.7083 18.7845L17.7084 18.7845Z" fill="white" />
    </svg>

)

export default Search
