import React from 'react'
import { type LucideProps } from 'lucide-react'

//  width="52" height="36" 
const SecureDelivery: React.FC<LucideProps> = (props: LucideProps) => (
<svg fill="currentColor" viewBox="0 0 52 36" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M47.7611 24.5439H42.822L39.5293 14.9971H12.0732L8.7805 24.5439H2.96342L0 36.0001H51.7127L47.7611 24.5439ZM38.4147 16.9064L41.0488 24.5439H28.0049L25.3708 16.9064H38.4147ZM13.1879 16.9064H23.5976L26.2317 24.5439H10.5528L13.1879 16.9064ZM2.21225 34.0907L4.18786 26.4532H14.4865L16.4621 34.0907H2.21225ZM17.6804 32.1537L16.2061 26.4531H19.646L17.6804 32.1537ZM28.2706 34.0907H18.7854L21.4196 26.4532H31.8293L34.4634 34.0907H28.2706ZM36.2367 34.0907L33.6025 26.4532H46.6473L49.2814 34.0907H36.2367Z" />
  <path d="M10.0843 0.108973C10.1056 -0.0363242 10.2915 -0.0363242 10.3128 0.108973L10.5061 1.43021C10.9095 4.18489 12.8287 6.34425 15.2772 6.79764L16.4515 7.01512C16.5808 7.03912 16.5808 7.24823 16.4515 7.27223L15.2772 7.4897C12.8286 7.94345 10.9091 10.1026 10.5061 12.8571L10.3128 14.1782C10.2915 14.3237 10.1056 14.3237 10.0843 14.1782L9.89096 12.8571C9.48763 10.1025 7.56837 7.9431 5.11989 7.4897L3.94562 7.27223C3.8163 7.24823 3.8163 7.03912 3.94562 7.01512L5.11989 6.79764C7.5685 6.3439 9.48794 4.18475 9.89096 1.43021L10.0843 0.108973Z" />
  <path d="M22.034 0.0726485C22.0473 -0.0242162 22.1635 -0.0242162 22.1768 0.0726485L22.2977 0.953473C22.5497 2.78993 23.7493 4.2295 25.2796 4.53176L26.0135 4.67675C26.0943 4.69275 26.0943 4.83215 26.0135 4.84815L25.2796 4.99314C23.7492 5.29563 22.5495 6.73507 22.2977 8.57142L22.1768 9.45212C22.1635 9.54911 22.0473 9.54911 22.034 9.45212L21.9132 8.57142C21.6611 6.73497 20.4616 5.2954 18.9313 4.99314L18.1973 4.84815C18.1165 4.83215 18.1165 4.69275 18.1973 4.67675L18.9313 4.53176C20.4616 4.22926 21.6613 2.78983 21.9132 0.953473L22.034 0.0726485Z" />
</svg>
)

export default SecureDelivery
