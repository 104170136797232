import React from 'react'
import { type LucideProps } from 'lucide-react'

const BagIcon: React.FC<LucideProps> = (props: LucideProps) => (
  <svg fill="none" viewBox="0 0 1019 1281" {...props}>
    <path d="m977.243 365.553h-175.407v-90.629c0-151.923-131.968-274.924-292.334-274.924-160.367 0-292.337 123.002-292.337 274.924v90.629h-175.4023c-23.3886 0-41.7627 17.051-41.7627 38.756v775.121c0 55.8 48.4456 100.76 108.582 100.76h801.84c60.138 0 108.578-44.96 108.578-100.76v-775.121c0-21.705-18.37-38.756-41.757-38.756zm-676.553-90.629c0-108.517 93.546-196.9408 208.812-196.9408 115.265 0 208.813 88.4218 208.813 196.9408v89.08h-417.625z" fill="currentColor"/>
  </svg>
)

export default BagIcon
